import React, { useState } from 'react';
import './App.css';

import { Editor } from '@tinymce/tinymce-react'
import { useForm, useField, splitFormProps } from 'react-form'

function SelectField(props) {
    const [field, fieldOptions, { options, ...rest }] = splitFormProps(props);
  
    const {
        value = options[0],
        setValue
    } = useField(field, fieldOptions);
  
    const handleSelectChange = e => {
      setValue(e.target.value);
    };
  
    return (
        <>
            <select {...rest} value={value} onChange={handleSelectChange} className="form-control">
            {options.map(option => (
                <option key={option} value={option}>
                {option}
                </option>
            ))}
            </select>
            {value !== 'zgodna' ? 
            (<div className="">
                <div className="clearfix form-group-separator"></div>
                <label>
                    Treści niedostępne:
                </label>
                <Niezgodnosc />
            </div>)
            : null}
        </>
    );
}

function NazwaPlacowki() {
    const { getInputProps } = useField("nazwaplacowki");
    return <input className="form-control" {...getInputProps()} />
}

function Architektura() {
    const { value, setValue } = useField("architektura");
    // return <textarea className="form-control" {...getInputProps()} />
    return <Editor
    // initialValue={value}
    newValue={value}
    init={{
        language: 'pl',
        menubar: false,
        plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'code insertdatetime media table paste'
        ],
        toolbar:
            'code | undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent'
    }}
    onChange={e => {setValue(e.target.getContent())}}
    />
}

function Niezgodnosc() {
    const { value, setValue } = useField("niezgodnosc");
    // return <textarea className="form-control" {...getInputProps()} />
    return <Editor
    apiKey="5v0vkdcrcvfgrf6u96jj1up8uiv92rpmmi3448kxbloyfcqk"
    // initialValue={value}
    newValue={value}
    // value={value}
    init={{
        language: 'pl',
        menubar: false,
        plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'code insertdatetime media table paste'
        ],
        toolbar:
            'code | undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent'
    }}
    onChange={e => {setValue(e.target.getContent())}}
    />
}

function AdresStrony() {
    const { getInputProps } = useField("adresstrony");
    return <input type="url" className="form-control" {...getInputProps()} />
}

function DataPublikacji() {
    const { getInputProps } = useField("datapublikacji");
    return <input className="form-control" {...getInputProps()} type="date" />
}

function DataAktualizacji() {
    const { getInputProps } = useField("dataaktualizacji");
    return <input className="form-control" {...getInputProps()} type="date" />
}

function DataPrzegladu() {
    const { getInputProps } = useField("dataprzegladu");
    return <input className="form-control" {...getInputProps()} type="date" />
}

function DataSporzadzenia() {
    const { getInputProps } = useField("datasporzadzenia");
    return <input className="form-control" {...getInputProps()} type="date" />
}

function Email() {
    const { getInputProps } = useField("email");
    return <input type="email" className="form-control" {...getInputProps()} />
}

function Telefon() {
    const { getInputProps } = useField("telefon");
    return <input className="form-control" {...getInputProps()} />
}

function OsobaOdpowiedzialna() {
    const { getInputProps } = useField("osobaodpowiedzialna");
    return <input className="form-control" {...getInputProps()} />
}

function MyForm(props) {

    const {
        Form,
        meta: { isSubmitting, canSubmit }
    } = useForm({
        onSubmit: async (values, instance) => {
            if (!values.zgodnosc) {
                values.zgodnosc = "częściowo zgodna"
            }
            if (!values.datapublikacji) {
                values.datapublikacji = (new Date()).toISOString().split('T')[0]
            }
            if (!values.dataaktualizacji) {
                values.dataaktualizacji = (new Date()).toISOString().split('T')[0]
            }
            if (!values.dataprzegladu) {
                values.dataprzegladu = (new Date()).toISOString().split('T')[0]
            }
            if (!values.datasporzadzenia) {
                values.datasporzadzenia = (new Date()).toISOString().split('T')[0]
            }
            console.log(values)
            props.setValues(values)
        },
        // debugForm: true
    });

    return (
        <Form>
            
            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Nazwa placówki: 
                </label>
                <NazwaPlacowki />
            </div>

            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Adres strony internetowej (w formacie https://adresstrony.pl): 
                </label>
                <AdresStrony />
            </div>
            
            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Imię i nazwisko osoby odpowiedzialnej za rozpatrywanie wniosków:
                </label>
                <OsobaOdpowiedzialna />
            </div>

            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Adres e-mail osoby odpowiedzialnej za rozpatrywanie wniosków:
                </label>
                <Email />
            </div>
            
            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Nr telefonu osoby odpowiedzialnej za rozpatrywanie wniosków:
                </label>
                <Telefon />
            </div>
            
            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Status pod względem zgodności strony z ustawą: 
                </label>
                <SelectField field="zgodnosc" options={["częściowo zgodna", "zgodna", "niezgodna"]}  />
            </div>
            
            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                Dostępność architektoniczna: 
                </label>
                <Architektura />
            </div>

            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Data publikacji strony internetowej: 
                </label>
                <DataPublikacji />
            </div>
            
            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Data ostatniej istotnej aktualizacji: 
                </label>
                <DataAktualizacji />
            </div>

            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Data przeglądu deklaracji: 
                </label>
                <DataPrzegladu />
            </div>
            
            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Oświadczenie sporządzono dnia: 
                </label>
                <DataSporzadzenia />
            </div>
            
            <div className="clearfix form-group-separator"></div>
    
            <div>
                <button type="submit" disabled={!canSubmit} className="btn btn-default">
                    Wygeneruj deklarację
                </button>
            </div>
        </Form>
    );
}


function Deklaracja(props) {
    const {nazwaplacowki, adresstrony, zgodnosc, niezgodnosc, datapublikacji, dataaktualizacji, dataprzegladu, datasporzadzenia, email, telefon, osobaodpowiedzialna, architektura} = props.formValues    

    let kod = `
        <p id="a11y-wstep"><span id="a11y-podmiot">${nazwaplacowki}</span> zobowiązuje się zapewnić dostępność strony internetowej <a id="a11y-url" href="${adresstrony}">${adresstrony}</a> zgodnie z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.</p>

        <ul>
            <li>Data publikacji strony internetowej: <time id="a11y-data-publikacja" datetime="${datapublikacji}">${datapublikacji}</time></li>
            <li>Data ostatniej istotnej aktualizacji: <time id="a11y-data-aktualizacja" datetime="${dataaktualizacji}">${dataaktualizacji}</time></li>
        </ul>

        <h2>Status pod względem zgodności z ustawą</h2>
        <p>Strona internetowa jest <strong id="a11y-status">${zgodnosc}</strong> z ustawą o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.</p>
        
        `

    if (zgodnosc !== 'zgodna') {

        kod += `<h3>Treści niedostępne:</h3>
        ${niezgodnosc}

        `
    }

    kod += `<h3>Przygotowanie deklaracji w sprawie dostępności</h3>
        <p>Oświadczenie sporządzono dnia <time id="a11y-data-sporzadzenie" datetime="${datasporzadzenia}">${datasporzadzenia}</time></p>
        
        <p>Data przeglądu deklaracji: <time id="a11y-data-przeglad" datetime="${dataprzegladu}">${dataprzegladu}</time></p>

        <p>Deklarację sporządzono na podstawie <strong>samooceny przeprowadzonej przez podmiot publiczny</strong>.</p>

        <p>Na stronie internetowej można korzystać ze standardowych skrótów klawiaturowych.</p>

        <h2 id="a11y-kontakt">Informacje zwrotne i dane kontaktowe</h2>

        <p>W przypadku problemów z dostępnością strony internetowej prosimy o kontakt. Osobą odpowiedzialną jest <span id="a11y-osoba">${osobaodpowiedzialna}</span>, adres poczty elektronicznej <span id="a11y-email">${email}</span>. Kontaktować można się także dzwoniąc na numer telefonu <span id="a11y-telefon">${telefon}</span>. Tą samą drogą można składać wnioski o udostępnienie informacji niedostępnej oraz składać skargi na brak zapewnienia dostępności.</p>

        <p id="a11y-procedura">Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich elementu. Można także zażądać udostępnienia informacji w formach alternatywnych, na przykład odczytanie niedostępnego cyfrowo dokumentu, opisania zawartości filmu bez audio deskrypcji itp. Żądanie powinno zawierać dane osoby zgłaszającej żądanie, wskazanie, o którą stronę internetową lub aplikację mobilną chodzi oraz sposób kontaktu. Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji w formie alternatywnej, powinna także określić formę tej informacji</p>

        <p>Podmiot publiczny powinien zrealizować żądanie niezwłocznie i nie później, niż w ciągu 7 dni. Jeżeli dotrzymanie tego terminu nie jest możliwe, podmiot publiczny niezwłocznie informuje o tym, kiedy realizacja żądania będzie możliwa, przy czym termin ten nie może być dłuższy niż 2 miesiące od dnia wystąpienia z żądaniem. Jeżeli zapewnienie dostępności nie jest możliwe, podmiot publiczny może zaproponować alternatywny sposób dostępu do informacji.</p>

        <p>W przypadku, gdy podmiot odmówi realizacji żądania zapewnienia dostępności lub alternatywnego dostępu do informacji, można złożyć skargę na takie działanie.</p>

        <p>Po wyczerpaniu wszystkich możliwości skargę można przesłać także do <a href="https://www.rpo.gov.pl/content/jak-zglosic-sie-do-rzecznika-praw-obywatelskich">Rzecznika Praw Obywatelskich.</a></p>

        <h2 id="a11y-architektura">Dostępność architektoniczna</h2>

        ${architektura}
    `
    return (
        <textarea className="form-control deklaracja" value={kod} />
    )
}


function App() {

    const [formValues, setFormValues] = useState({});
    // let formValues = null

    return (
        <div className="App">
            {/* <MyForm formValues={formValues} setFormValues={setFormValues} /> */}
            <MyForm setValues={(values) => { setFormValues(values)} } />
            {formValues.nazwaplacowki && formValues.adresstrony && formValues.zgodnosc && formValues.datapublikacji && formValues.dataaktualizacji && formValues.dataprzegladu && formValues.datasporzadzenia && formValues.email && formValues.telefon && formValues.osobaodpowiedzialna  ? <Deklaracja formValues={formValues} /> : '' }
        </div>
    );
}

export default App;
